﻿/// <reference path="../../main.scss" />
////
// CSS3
////

// TEXT
// ----------------------------------------------------

@mixin text-shadow ($textshadow: 1px 1px 1px rgba(0, 0, 0, 0.3)) {
    text-shadow: $textshadow;
}

@mixin user-select ($value: none) {
    cursor: default;
    user-select: $value;
}

@mixin wrap-words {
    word-break: break-all;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

// Prevents words being broken by letter - IE compatible 
@mixin wrap-words-notonletter {
    word-break: normal;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: none;
}

// ====================================================
// The following are intended for safe replacements of
// global @extend %placeholders, but without the
// inheritance and compilation-order issues
// ie, use mixin rather than @extend
// but this might be fixed now in Gulp-sass v4+!
// ====================================================

// TEXT MANIPULTAION
// ----------------------------------------------------

@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// text is still read by screen readers
@mixin hidden-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
}

@mixin unhidden-text {
    border: 0;
    clip: auto;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
    overflow: visible;
    position: relative;
}

@mixin truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/// Image replacement
@mixin ir {
    font: 0/0 a;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

// DISPLAY
// ----------------------------------------------------

@mixin block {
    display: block;
}

@mixin inline-block {
    display: inline-block;
}

@mixin hide {
    display: none;
}

@mixin invisible {
    opacity: 0;
}

@mixin visible {
    opacity: 1;
}

// ALIGNMENT
// ----------------------------------------------------

@mixin left {
    float: left;
}

@mixin right {
    float: right;
}

@mixin align-left {
    text-align: left;
}

@mixin align-right {
    text-align: right;
}

@mixin center {
    text-align: center;
}

@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
