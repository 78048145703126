/*! 111 CSS by JamCow */
/**
 * Last-index v2
 * Less beautiful code
 * Better performance
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  /* Change the default font family in all browsers (opinionated). */
  font-family: Verdana, Geneva, sans-serif;
  font-size: 100%;
  line-height: 1.5em;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2rem;
  line-height: 3rem;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 1.5rem 0;
}

h2 {
  font-size: 1.5rem;
  line-height: 3rem;
  margin: 1.5rem 0;
}

h3 {
  font-size: 1.17rem;
  line-height: 1.5rem;
  margin: 1.5rem 0;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1.5rem 0;
}

h5 {
  font-size: 0.83rem;
  line-height: 1.5rem;
  margin: 1.5rem 0;
}

h6 {
  font-size: 0.67rem;
  line-height: 1.5rem;
  margin: 1.5rem 0;
}

/* Grouping content
       ========================================================================== */
/**
       * Set 1 unit of vertical rhythm on the top and bottom margin.
       */
blockquote {
  margin: 1.5rem 0;
}

dl,
ol,
ul {
  margin: 1.5rem 0;
}

/**
       * Turn off margins on nested lists.
       */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 0;
}

ol,
ul {
  padding: 0 0 0 0;
}

/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1.5rem 0;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
       * Set 1 unit of vertical rhythm on the top and bottom margin.
       */
p,
pre {
  margin: 1.5rem 0;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: Verdana, Geneva, sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.5em;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
  /*
         * 1. Set 1 unit of vertical rhythm on the top and bottom margin.
         * 2. Set consistent space for the list style image.
         */
  margin: 1.5rem 0;
  /* 1 */
  padding: 0 0 0 0;
  /* 2 */
  /**
         * Turn off margins on nested lists.
         */
}

menu menu,
ol menu,
ul menu {
  margin: 0;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* stylelint-disable */
@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/* stylelint-enable */
::-moz-selection {
  background: #1d3c82;
  color: white;
  text-shadow: none;
}

::selection {
  background: #1d3c82;
  color: white;
  text-shadow: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100%;
}

body {
  font-size: 1em;
  background-color: #ffffff;
  color: #666666;
  min-height: 100vh;
  margin: 0;
}

picture {
  display: block;
}

a,
a:link {
  color: #3c75be;
}

a:visited {
  color: #3c75be;
}

a:hover {
  color: #3c75be;
}

a:focus {
  color: inherit;
  outline: -webkit-focus-ring-color auto 5px;
}

a:active {
  color: #305d97;
}

a:hover,
a:active {
  outline: 0;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  border-style: none;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

img[src$=".svg"] {
  width: 100%;
}

button,
input,
select,
textarea {
  vertical-align: baseline;
}

input[type="search"] {
  width: 100%;
}

button:hover {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body.fonts-loaded {
  font-family: "fira_sans", Verdana, Geneva, sans-serif;
}

@font-face {
  font-family: 'fira_sans';
  src: url("/assets/fonts/FiraSans-Bold.woff2") format("woff2"), url("/assets/fonts/FiraSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'fira_sans';
  src: url("/assets/fonts/FiraSans-Regular.woff2") format("woff2"), url("/assets/fonts/FiraSans-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'fira_sans';
  src: url("/assets/fonts/FiraSans-Regular.woff2") format("woff2"), url("/assets/fonts/FiraSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ffdin_pro';
  src: url("/assets/fonts/315D27_2_0.woff2") format("woff2"), url("/assets/fonts/315D27_2_0.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ffdin_pro';
  src: url("/assets/fonts/315D27_2_0.woff2") format("woff2"), url("/assets/fonts/315D27_2_0.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ffdin_pro';
  src: url("/assets/fonts/315D27_2_0.woff2") format("woff2"), url("/assets/fonts/315D27_2_0.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  line-height: 1.6;
}

body {
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 400;
}

.fonts-loaded body {
  font-family: "fira_sans", Verdana, Geneva, sans-serif;
}

a:hover {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia, Utopia, Charter, serif;
  font-weight: 700;
  margin: 0 0 1.5rem 0;
  text-rendering: optimizelegibility;
}

.fonts-loaded h1, .fonts-loaded
h2, .fonts-loaded
h3, .fonts-loaded
h4, .fonts-loaded
h5, .fonts-loaded
h6 {
  font-family: "ffdin_pro", Georgia, Utopia, Charter, serif;
}

h1 {
  font-size: 2.5rem;
  line-height: 0.0375;
  line-height: 1.5;
}

h2 {
  font-size: 1.75rem;
  line-height: 0.05357;
  line-height: 1.5;
}

h3 {
  font-size: 1.375rem;
  line-height: 0.06818;
  line-height: 1.5;
}

h4 {
  font-size: 1.125rem;
  line-height: 0.08333;
  line-height: 1.5;
}

h5 {
  font-size: 1rem;
  line-height: 0.09375;
  line-height: 1.5;
}

h6 {
  font-size: 1rem;
  line-height: 0.09375;
  line-height: 1.5;
}

p {
  margin: 0 0 1.5rem 0;
}

p:last-child {
  margin-bottom: 0;
}

blockquote {
  padding: 0 0 0 1.5rem;
  margin: 0 0 1.5rem 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #d9d9d9;
  padding: 0;
  margin: 1.5rem 0;
}

ins {
  background: #ffff00;
  color: black;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: Monaco, "Courier New", Courier, monospace, serif;
  font-size: 1rem;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

pre code {
  display: block;
  padding: 1rem;
}

code {
  padding: 0 1rem;
}

q {
  quotes: none;
}

q::before, q::after {
  content: "";
  content: none;
}

small,
.small {
  font-weight: normal;
  font-size: 80%;
}

summary:hover {
  cursor: pointer;
}

summary:focus {
  outline: none;
}

#mq_page,
#mq_menu {
  transition: width 0.001s;
}

#mq_page::after,
#mq_menu::after {
  content: "xsdefault";
  display: none;
}

@media screen and (min-width: 21.26em) {
  #mq_page {
    width: 20px;
  }
  #mq_page::after {
    content: "smallest";
  }
}

@media screen and (min-width: 37.51em) {
  #mq_page {
    width: 40px;
  }
  #mq_page::after {
    content: "small";
  }
}

@media screen and (min-width: 50.01em) {
  #mq_page {
    width: 60px;
  }
  #mq_page::after {
    content: "medium";
  }
}

@media screen and (min-width: 61.26em) {
  #mq_page {
    width: 80px;
  }
  #mq_page::after {
    content: "large";
  }
}

@media screen and (min-width: 78.76em) {
  #mq_page {
    width: 100px;
  }
  #mq_page::after {
    content: "gridmax";
  }
}

@media screen and (min-width: 78.76em) {
  #mq_page {
    width: 120px;
  }
  #mq_page::after {
    content: "larger";
  }
}

@media screen and (min-width: 118.76em) {
  #mq_page {
    width: 140px;
  }
  #mq_page::after {
    content: "largest";
  }
}

@media screen and (min-width: 25.01em) {
  #mq_menu {
    width: 160px;
  }
  #mq_menu::after {
    content: "menusmall";
  }
}

@media screen and (min-width: 37.51em) {
  #mq_menu {
    width: 180px;
  }
  #mq_menu::after {
    content: "menumedium";
  }
}

@media screen and (min-width: 66.135em) {
  #mq_menu {
    width: 200px;
  }
  #mq_menu::after {
    content: "menularge";
  }
}

@media screen and (min-width: 70.26em) {
  #mq_menu {
    width: 220px;
  }
  #mq_menu::after {
    content: "menuxlarge";
  }
}

#js-obs1 {
  position: absolute;
  top: 160px;
  left: 0;
  height: 100px;
  width: 1px;
}

:target::before {
  content: "";
  display: block;
  height: 5.1875rem;
  margin: -5.1875rem 0 0;
}

@media screen and (min-width: 66.135em) {
  :target::before {
    height: 8.875rem;
    margin: -8.875rem 0 0;
  }
}

picture[data-picturesize*="sq"]::before, picture[data-picturesize*="sq"]::after {
  content: '';
  display: table;
  clear: both;
  width: 1px;
  margin-left: -1px;
}

picture[data-picturesize*="sq"]::before {
  float: left;
  padding-bottom: 100%;
}

.o-g {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

@media screen and (min-width: 50.01em) {
  .o-g {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

.o-w {
  margin: 0 auto;
  width: 100%;
  max-width: 78.75em;
}

.o-w--full {
  max-width: 100%;
}

.o-w--wide {
  max-width: 96.25em;
}

.o-w--tight {
  max-width: 60em;
}

.o-w--tighter {
  max-width: 50.625em;
}

.o-w--tightest {
  max-width: 41.25em;
}

.g {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.gc {
  flex: 1;
}

.g--flex > .gc {
  display: flex;
}

.g--fit > .gc {
  flex: 1;
}

.g--autosize .gc {
  flex: none;
  max-width: 100%;
}

.g--full > .gc {
  flex: 0 0 100%;
  max-width: 100%;
}

.g--1-2 > .gc {
  flex: 0 0 50%;
  max-width: 50%;
}

.g--1-3 > .gc {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.g--1-4 > .gc {
  flex: 0 0 25%;
  max-width: 25%;
}

@media screen and (max-width: 37.5em) {
  .g__sm--fit > .gc {
    flex: 1;
  }
  .g__sm--1 > .gc {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .g__sm--1-2 > .gc {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .g__sm--1-3 > .gc {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .g__sm--1-4 > .gc {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 37.51em) and (max-width: 50em) {
  .g__md--fit > .gc {
    flex: 1;
  }
  .g__md--1 > .gc {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .g__md--1-2 > .gc {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .g__md--1-3 > .gc {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .g__md--1-4 > .gc {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 50.01em) {
  .g__lg--fit > .gc {
    flex: 1;
  }
  .g__lg--1 > .gc {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .g__lg--1-2 > .gc {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .g__lg--1-3 > .gc {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .g__lg--1-4 > .gc {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .g__lg--1-5 > .gc {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media screen and (max-width: 37.5em) {
  .g .gc__sm--1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .g .gc__sm--1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .g .gc__sm--1-3 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .g .gc__sm--2-3 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .g .gc__sm--1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .g .gc__sm--3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media screen and (min-width: 37.51em) and (max-width: 50em) {
  .g .gc__md--1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .g .gc__md--1-2,
  .g .gc__md--2-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .g .gc__md--1-3 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .g .gc__md--2-3 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .g .gc__md--1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .g .gc__md--3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media screen and (min-width: 50.01em) {
  .g .gc__lg--1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .g .gc__lg--1-2,
  .g .gc__lg--2-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .g .gc__lg--1-3 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .g .gc__lg--2-3 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .g .gc__lg--1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .g .gc__lg--3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .g .gc__lg--1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .g .gc__lg--2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .g .gc__lg--3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .g .gc__lg--4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

.g--top,
.g .gc--top {
  align-items: flex-start;
}

.g--bottom,
.g .gc--bottom {
  align-items: flex-end;
}

.g--middle,
.g .gc--middle {
  align-items: center;
}

.g--center,
.g .gc--center {
  justify-content: center;
}

.g .gc--autosize {
  flex: none;
  max-width: 100%;
}

.g .gc--full {
  flex: 0 0 100%;
  max-width: 100%;
}

.g--rtl,
.g .gc--rtl {
  flex-direction: row-reverse;
}

.g--gutters {
  margin: -0.75rem 0 0.75rem -0.75rem;
}

.g--gutters > .gc {
  padding: 0.75rem 0 0 0.75rem;
}

@media screen and (min-width: 37.51em) and (max-width: 50em) {
  .g--gutters {
    margin: -1.125rem 0 1.125rem -1.125rem;
  }
  .g--gutters > .gc {
    padding: 1.125rem 0 0 1.125rem;
  }
}

@media screen and (min-width: 50.01em) {
  .g--gutters {
    margin: -1.5rem 0 1.5rem -1.5rem;
  }
  .g--gutters > .gc {
    padding: 1.5rem 0 0 1.5rem;
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  zoom: 1;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon.xs {
  font-size: 0.875rem;
}

.icon.s {
  font-size: 0.9375rem;
}

.icon.m {
  font-size: 1rem;
}

.icon.l {
  font-size: 1.125rem;
}

.icon.xl {
  font-size: 1.375rem;
}

.icon.xxl {
  font-size: 1.75rem;
}

.icon.xxxl {
  font-size: 2.5rem;
}

.icon.xxxxl {
  font-size: 3.75rem;
}

.button--subnav .icon--toggle > * {
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
}

.button--subnav .icon--toggle .icon--arrowdown {
  transform: rotate(0deg);
}

.button--subnav.active .icon--toggle .icon--arrowdown {
  transform: rotate(180deg);
}

.icon--switch {
  height: 0.51613em;
  font-size: 4rem;
}

@supports (transform: translateX(0%)) {
  .icon--switch * {
    transition: transform 0.2s ease-in-out;
  }
}

.icon--switch .icon--switch__back {
  fill: inherit;
}

.icon--switch .icon--switch__border {
  stroke: inherit;
}

.icon--switch .icon--switch__dotoff {
  fill: #d9d9d9;
  opacity: 1;
}

.icon--switch .icon--switch__dot {
  fill: #d9d9d9;
  opacity: 0;
}

@supports (transform: translateX(0%)) {
  .icon--switch .icon--switch__dotoff {
    opacity: 0;
  }
  .icon--switch .icon--switch__dot {
    transform: translateX(-47.58%);
    opacity: 1;
  }
}

.icon--switch:hover, .icon--switch:focus, .icon--switch:active {
  cursor: pointer;
}

.icon--switch.active .icon--switch__dotoff {
  fill: #ffffff;
  opacity: 0;
}

.icon--switch.active .icon--switch__dot {
  fill: #ffffff;
  opacity: 1;
}

@supports (transform: translateX(0%)) {
  .icon--switch.active .icon--switch__dotoff {
    opacity: 0;
  }
  .icon--switch.active .icon--switch__dot {
    opacity: 1;
    transform: translateX(0%);
  }
}

.icon--switch.hasstate:hover .icon--switch__dot, .icon--switch.hasstate:focus .icon--switch__dot, .icon--switch.hasstate:active .icon--switch__dot {
  fill: #ffffff;
  transform: translateX(0%);
}

.icon--switch.default {
  color: #ffffff;
}

.icon--switch.default .icon--switch__border {
  stroke: #666666;
  stroke-opacity: 0.5;
}

.icon--switch.default .icon--switch__dot {
  fill: #666666;
}

.icon--switch.default.active {
  color: #3c75be;
}

.icon--switch.default.active .icon--switch__border {
  stroke: inherit;
}

.icon--switch.default.active .icon--switch__dot {
  fill: #ffffff;
}

@keyframes spinout {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(-360deg) scale(0.2);
    opacity: 0;
  }
}

@keyframes spinin {
  0% {
    transform: rotate(360deg) scale(0.2);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}

.testexamples .icon {
  font-size: 2.5rem;
}

.testexamples .icon > use {
  transform-origin: center center;
}

.testexample1 .icon--checkbox__box {
  color: red;
}

.testexample1 .icon--checkbox__tick {
  color: lime;
}

.testexample2 > * {
  transition: all 0.3s ease-in-out;
}

.testexample2 .icon--close {
  transform: rotate(-180deg) scale(0.2);
  opacity: 0;
}

.testexample2 .icon--burger {
  transform: rotate(0deg) scale(0.8);
  opacity: 1;
}

.testexample2:hover .icon--close {
  transform: rotate(0deg) scale(0.7);
  opacity: 1;
}

.testexample2:hover .icon--burger {
  transform: rotate(180deg) scale(0.2);
  opacity: 0;
}

.testgrid .c-cell {
  background-color: #cccccc;
}

.testgrid .testfit .gc {
  min-width: 140px;
}

.testgrid .testfixedwidth .c-cell {
  background-color: red;
  width: 300px;
}

.o-l {
  list-style: none;
  padding: 0;
  margin: 0;
}

.o-l--h {
  display: flex;
  flex-wrap: wrap;
}

.o-l--h li {
  padding-right: 1rem;
}

.o-dl {
  display: flex;
  margin: 0;
}

.o-l--portfolio {
  display: grid;
  grid-gap: 20px;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-auto-rows: auto;
}

@media screen and (min-width: 37.51em) {
  .o-l--portfolio {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

.o-l--history {
  list-style: square;
  margin-left: 20px;
}

a {
  color: #3c75be;
  text-decoration: none;
  text-decoration-skip: ink;
}

a:link {
  color: #3c75be;
}

a:visited {
  color: #3c75be;
}

a:hover {
  color: #3c75be;
  text-decoration: underline;
}

a:focus {
  color: #3669ab;
  text-decoration: underline;
}

a:active {
  color: #305d97;
}

.alt {
  color: #5aa2e0;
  text-decoration: underline;
}

.alt:link {
  color: #5aa2e0;
}

.alt:visited {
  color: #5aa2e0;
}

.alt:hover {
  color: #5aa2e0;
  text-decoration: none;
}

.alt:focus {
  color: #4596dc;
  text-decoration: none;
}

.alt:active {
  color: #2f8ad8;
}

.button {
  border: none;
  appearance: none;
  text-align: center;
  padding: 2px;
  background-color: transparent;
  border-radius: 10px;
  min-width: 150px;
  position: relative;
  font-size: 1rem;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 700;
}

.fonts-loaded .button {
  font-family: "fira_sans", Verdana, Geneva, sans-serif;
}

.button:link, .button:visited {
  text-decoration: none;
}

.button::before {
  content: "";
  display: block;
  border-radius: 9px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.button > span {
  position: relative;
  border-radius: 8px;
  z-index: 2;
  padding: 1rem 0.75rem;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button > span .icon {
  padding-right: 5px;
  font-size: 1.75rem;
}

.button i {
  font-style: normal;
}

.button:hover, .button:focus, .button:active, .button.hover {
  text-decoration: none;
  cursor: pointer;
}

.button:hover::before, .button:focus::before, .button:active::before, .button.hover::before {
  opacity: 1;
}

.button:hover span, .button:focus span, .button:active span, .button.hover span {
  background-color: #ffffff;
}

@media screen and (max-width: 37.5em) {
  .button {
    display: block;
  }
  .button.button--link, .button.button--linkAlt {
    display: inline-block;
  }
}

@media screen and (min-width: 37.51em) {
  .button {
    display: inline-block;
  }
}

.button:not([class*="button--"]) {
  min-width: auto;
  color: #cc5e7b;
}

.button:not([class*="button--"]):link, .button:not([class*="button--"]):visited {
  color: #cc5e7b;
}

.button:not([class*="button--"]) span {
  background-color: #ffffff;
}

.button:not([class*="button--"]):hover, .button:not([class*="button--"]):focus, .button:not([class*="button--"]):active {
  color: #333333;
}

.button:not([class*="button--"]):hover span, .button:not([class*="button--"]):focus span, .button:not([class*="button--"]):active span {
  background-color: #ffffff;
}

.button:not([class*="button--"]):active {
  color: #333333;
}

.button:not([class*="button--"]):active span {
  background-color: #ffffff;
}

.button--primary {
  color: #cc5e7b;
  background-color: #cc5e7b;
  background-image: linear-gradient(to top, #cc5e7b 0%, #bb7aaa 100%);
}

.button--primary:link, .button--primary:visited {
  color: #cc5e7b;
}

.button--primary span {
  background-color: #ffffff;
}

.button--primary:hover, .button--primary:focus, .button--primary:active {
  color: #ffffff;
}

.button--primary:hover span, .button--primary:focus span, .button--primary:active span {
  background-color: transparent;
}

.button--primary:active {
  color: #ffffff;
}

.button--primary:active span {
  background-color: transparent;
}

.button--primary::before {
  background-image: linear-gradient(to right, #cc5e7b 0%, #bb7aaa 100%);
}

.button--secondary {
  color: #cc5e7b;
}

.button--secondary:link, .button--secondary:visited {
  color: #cc5e7b;
}

.button--secondary span {
  background-color: #ffffff;
}

.button--secondary:hover, .button--secondary:focus, .button--secondary:active {
  color: #cc5e7b;
}

.button--secondary:hover span, .button--secondary:focus span, .button--secondary:active span {
  background-color: #ffffff;
}

.button--secondary:active {
  color: #cc5e7b;
}

.button--secondary:active span {
  background-color: #ffffff;
}

.button--secondary::before {
  background-image: linear-gradient(to top, #cc5e7b 0%, #bb7aaa 100%);
}

.button--light {
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 0;
}

.button--light:link, .button--light:visited {
  color: #ffffff;
}

.button--light span {
  background-color: transparent;
}

.button--light:hover, .button--light:focus, .button--light:active {
  color: #cc5e7b;
  border-color: #ffffff;
}

.button--light:hover span, .button--light:focus span, .button--light:active span {
  background-color: #ffffff;
}

.button--light:active {
  color: #cc5e7b;
}

.button--light:active span {
  background-color: #ffffff;
}

.button--dark {
  color: #333333;
  border: 2px solid #333333;
  padding: 0;
}

.button--dark:link, .button--dark:visited {
  color: #333333;
}

.button--dark span {
  background-color: transparent;
}

.button--dark:hover, .button--dark:focus, .button--dark:active {
  color: #cc5e7b;
  border-color: #cc5e7b;
}

.button--dark:hover span, .button--dark:focus span, .button--dark:active span {
  background-color: #ffffff;
}

.button--dark:active {
  color: #cc5e7b;
}

.button--dark:active span {
  background-color: #ffffff;
}

.button--alt {
  min-width: auto;
  color: #333333;
  border: 0px solid transparent;
  padding: 0;
}

.button--alt:link, .button--alt:visited {
  color: #333333;
}

.button--alt span {
  background-color: #ffffff;
}

.button--alt:hover, .button--alt:focus, .button--alt:active {
  color: #cc5e7b;
}

.button--alt:hover span, .button--alt:focus span, .button--alt:active span {
  background-color: #ffffff;
}

.button--alt:active {
  color: #cc5e7b;
}

.button--alt:active span {
  background-color: #ffffff;
}

.button--menu,
.button--subnav,
.nav--actions .button {
  min-width: auto;
  display: inline-block;
}

.button--menu span,
.button--subnav span,
.nav--actions .button span {
  padding: 0;
}

.button--altprimary {
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 0;
}

.button--altprimary:link, .button--altprimary:visited {
  color: #ffffff;
}

.button--altprimary span {
  background-color: transparent;
}

.button--altprimary:hover, .button--altprimary:focus, .button--altprimary:active {
  color: #cc5e7b;
  border-color: #ffffff;
}

.button--altprimary:hover span, .button--altprimary:focus span, .button--altprimary:active span {
  background-color: #ffffff;
}

.button--altprimary:active {
  color: #cc5e7b;
}

.button--altprimary:active span {
  background-color: #ffffff;
}

.button--altsecondary {
  color: #ffffff;
}

.button--altsecondary:link, .button--altsecondary:visited {
  color: #ffffff;
}

.button--altsecondary span {
  background-color: transparent;
}

.button--altsecondary:hover, .button--altsecondary:focus, .button--altsecondary:active {
  color: #cc5e7b;
}

.button--altsecondary:hover span, .button--altsecondary:focus span, .button--altsecondary:active span {
  background-color: #ffffff;
}

.button--altsecondary:active {
  color: #cc5e7b;
}

.button--altsecondary:active span {
  background-color: #ffffff;
}

.button--link {
  color: #cc5e7b;
  min-width: auto;
}

.button--link:link, .button--link:visited {
  color: #cc5e7b;
}

.button--link span {
  background-color: #ffffff;
}

.button--link:hover, .button--link:focus, .button--link:active {
  color: #333333;
}

.button--link:hover span, .button--link:focus span, .button--link:active span {
  background-color: #ffffff;
}

.button--link:active {
  color: #333333;
}

.button--link:active span {
  background-color: #ffffff;
}

.button--link span {
  padding: 0;
}

.button--linkAlt {
  color: #333333;
  min-width: auto;
}

.button--linkAlt:link, .button--linkAlt:visited {
  color: #333333;
}

.button--linkAlt span {
  background-color: #ffffff;
}

.button--linkAlt:hover, .button--linkAlt:focus, .button--linkAlt:active {
  color: #cc5e7b;
}

.button--linkAlt:hover span, .button--linkAlt:focus span, .button--linkAlt:active span {
  background-color: #ffffff;
}

.button--linkAlt:active {
  color: #cc5e7b;
}

.button--linkAlt:active span {
  background-color: #ffffff;
}

.button--linkAlt span {
  padding: 0;
}

.button--inline {
  display: inline;
}

.button--full {
  width: 100%;
}

.button.xsmall {
  font-size: 0.75rem;
}

.button.small {
  font-size: 0.875rem;
}

.button.large {
  font-size: 1.125rem;
}

.button.xlarge {
  font-size: 1.25rem;
}

.button.full {
  display: block !important;
}

.button.block {
  width: 100%;
}

.ng-cloak {
  display: none;
}

.media {
  margin: 0 0 1.5rem 0;
}

.media::before, .media::after {
  content: '';
  display: table;
}

.media::after {
  clear: both;
}

.media__content {
  overflow: hidden;
  _overflow: visible;
  zoom: 1;
}

.media__image {
  float: left;
  margin: 0 1rem 0 0;
}

.media__image .image {
  display: block;
}

.media__image--alt {
  float: right;
  margin: 0 0 0 1rem;
}

@media screen and (min-width: 50.01em) {
  .media__image--sm {
    max-width: 50%;
  }
}

.media__image--portfolioCard {
  max-width: 50%;
  width: 200px;
}

@media screen and (min-width: 50.01em) {
  .media__image--portfolioCard {
    width: 200px;
  }
}

@media screen and (max-width: 50em) {
  .media__image--sm {
    float: none;
    display: block;
    text-align: center;
    margin: 0 auto 1.5rem;
  }
}

.o-main__container {
  max-width: 1200px;
  margin: 0 auto;
}

.c-portfolioCard {
  display: block;
  border: 1px solid #333333;
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease-out;
}

.o-l__item > .c-portfolioCard.is-active, .o-l__item > .c-portfolioCard:hover {
  box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.12);
  transform: translateX(-10px) translateY(-10px);
}

.c-portfolioCard__front {
  background-image: linear-gradient(0deg, #888888, #b3b3b3 50%, #c2c2c2 50%, #c2c2c2);
  background-size: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}

.c-portfolioCard__front::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/assets/img/liney.png");
  background-size: 210px 210px;
  opacity: 0.3;
}

.c-portfolioCard--ldc .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #dcc469, #dcc469 50%, #cdb65f 50%, #b59211);
}

.c-portfolioCard--girlguiding .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #9bc9ff, #9bc9ff 50%, #86bcfa 50%, #1b85ff);
}

.c-portfolioCard--ffl .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #ffadb0, #ffadb0 50%, #ff9397 50%, #ff2d35);
}

.c-portfolioCard--brandon .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #b2e23f, #b2e23f 50%, #a4d235 50%, #709c05);
}

.c-portfolioCard--agas .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #ffa2d9, #ffa2d9 50%, #ff88ce 50%, #ff22a4);
}

.c-portfolioCard--ofwat .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #a2c6f6, #a2c6f6 50%, #91b9ee 50%, #1a7cff);
}

.c-portfolioCard--quaymarinas .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #95afd4, #95afd4 50%, #8ca3c4 50%, #2c68be);
}

.c-portfolioCard--roland .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #de93ff, #de93ff 50%, #d679ff 50%, #b713ff);
}

.c-portfolioCard--altitude .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #ff975f, #ff975f 50%, #fe8746 50%, #de4e00);
}

.c-portfolioCard--weston .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #7bbfff, #7bbfff 50%, #61b3ff 50%, #0082fa);
}

.c-portfolioCard--gromitunleashed .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #76c1ff, #76c1ff 50%, #5cb5ff 50%, #0086f5);
}

.c-portfolioCard--oneeducation .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #ff949c, #ff949c 50%, #ff7a84 50%, #ff1426);
}

.c-portfolioCard--ncc .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #77e1d9, #77e1d9 50%, #6cd3cb 50%, #11c8b9);
}

.c-portfolioCard--meadeking .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #abc4e6, #abc4e6 50%, #9eb7d9 50%, #317be0);
}

.c-portfolioCard--vistair .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #88d2fa, #88d2fa 50%, #77c6f2 50%, #04a5ff);
}

.c-portfolioCard--calendarclub .c-portfolioCard__front {
  background-image: linear-gradient(180deg, #a0b2ff, #a0b2ff 50%, #869eff 50%, #204cff);
}

.c-portfolioCard__img {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50%;
  text-align: center;
}

.c-portfolioCard__back {
  display: none;
}

@media screen and (min-width: 50.01em) {
  .o-main__projectcontainer {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: [intro bodyimg] 200px 1fr [bodyimg] 1fr [intro projimg] 200px [projimg];
    grid-auto-rows: auto;
  }
}

.o-main__projectimg {
  grid-area: projimg;
  grid-row: 1;
}

.o-main__projectbody {
  grid-column: intro / projimg;
  grid-row: 1;
}

.o-main__projectbody div + h2 {
  margin-top: 1.5rem;
}

.o-main__projectimgs {
  grid-column: 1 / -1;
  grid-row: 2;
}

div .o-main__subtitle {
  margin-top: 20px;
  margin-bottom: 5px;
}

@media screen and (min-width: 50.01em) {
  .o-main__figure {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas: "body body img img img";
  }
  .o-main__figure:nth-child(odd) {
    grid-template-areas: "img img img body body";
  }
}

.o-main__bodyfigcaption {
  margin: 40px 20px;
  grid-area: body;
}

.o-main__bodyimg {
  border: 1px solid #333333;
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 20px 0 40px;
  grid-area: img;
  display: inline-block;
}

.c-header {
  background-image: url("/assets/img/headliney.png");
  background-size: 130px 130px;
  padding: 50px 0;
  text-align: center;
}

@media screen and (max-width: 50em) {
  .c-header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 50.01em) {
  .c-header {
    display: grid;
    grid-gap: 20px;
    margin: 0 auto;
    grid-template-columns: 1fr 200px 260px 200px 1fr;
    grid-auto-rows: auto;
  }
}

.c-header a {
  color: #333;
}

.c-header__img {
  background-color: #ffffff;
  border: 1px solid #333333;
  max-width: 260px;
}

@media screen and (max-width: 50em) {
  .c-header__img {
    margin-bottom: 20px;
  }
}

.c-header__img + .c-header__s {
  display: flex;
  align-items: center;
  font-size: 1.375rem;
}

.o-main__projectimg {
  max-width: 200px;
}

.c::before, .c::after {
  content: '';
  display: table;
}

.c::after {
  clear: both;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vh {
  font-style: normal;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

.vh-un {
  border: 0;
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  position: relative;
}

.no-select {
  cursor: default;
  user-select: none;
}

.block,
.show {
  display: block;
}

.hide,
.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.scrollLock {
  overflow-y: hidden;
}

.ir {
  font: 0/0 a;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.left {
  float: left;
}

.right {
  float: right;
}

.align--left {
  text-align: left;
}

.align--right {
  text-align: right;
}

.center,
.align--center {
  text-align: center;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 37.51em) {
  .u-show--s {
    display: none;
  }
}

@media screen and (max-width: 37.5em) {
  .u-show--m {
    display: none;
  }
}

@media screen and (min-width: 37.51em) {
  .u-vh--s {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }
}

@media screen and (max-width: 37.5em) {
  .u-vh--m {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }
}

@media print {
  * {
    background: transparent !important;
    color: #000000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  pre,
  blockquote {
    border: 1px solid #8f8f8f;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .g--gutters {
    margin: 0;
  }
}

/* stylelint-disable */
/* stylelint-enable */
