/// <reference path="../main.scss" />

////
/// PRINT
/// Adapted from: Html5 Boilerplate
/// @link http://html5boilerplate.com
////

@media print {
    * {
        background: transparent !important;
        color: $COLOR-Black !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    pre,
    blockquote {
        border: 1px solid $COLOR-GreyLight;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .g--gutters {
        margin: 0;
    }
}
