/// <reference path="../main.scss" />

////
/// TEMPLATE
////

/// VARIABLES
/// ----------------------------------------------------

/// BASE
/// ----------------------------------------------------

$projects: (
    ("ldc", $COLOR-PORTFOLIO-ldc),
    ("girlguiding", $COLOR-PORTFOLIO-girlguiding),
    ("ffl", $COLOR-PORTFOLIO-ffl),
    ("brandon", $COLOR-PORTFOLIO-brandon),
    ("agas", $COLOR-PORTFOLIO-agas),
    ("ofwat", $COLOR-PORTFOLIO-ofwat),
    ("quaymarinas", $COLOR-PORTFOLIO-quaymarinas),
    ("roland", $COLOR-PORTFOLIO-roland),
    ("altitude", $COLOR-PORTFOLIO-altitude),
    ("weston", $COLOR-PORTFOLIO-weston),
    ("gromitunleashed", $COLOR-PORTFOLIO-gromitunleashed),
    ("oneeducation", $COLOR-PORTFOLIO-oneeducation),
    ("ncc", $COLOR-PORTFOLIO-ncc),
    ("meadeking", $COLOR-PORTFOLIO-meadeking),
    ("vistair", $COLOR-PORTFOLIO-vistair),
    ("calendarclub", $COLOR-PORTFOLIO-calendarclub)
);

$c1: hsl(78, 72%, 57%);
$c2: hsl(78, 64%, 52%);
$c3: hsl(78, 89%, 35%);

@mixin portfolios($p) {
    @each $project in $p {
        //
        $name: nth($project, 1);
        $col: nth($project, 2);

        $col1: adjust-color($col, $saturation: +10%, $lightness: 5%);
        $col3: adjust-color($col, $saturation: +30%, $lightness: -20%);

        .c-portfolioCard--#{$name} {
            .c-portfolioCard__front {
                background-image: linear-gradient(180deg, $col1, $col1 50%, $col 50%, $col3);
            }
        }
    }
}

.o-main__container {
    max-width: 1200px;
    margin: 0 auto;
}

.c-portfolioCard {
    display: block;
    border: 1px solid #333333;
    box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.12);
    // transform-style: preserve-3d;
    // backface-visibility: hidden;
    transition: all 0.2s ease-out;
}

.o-l__item > .c-portfolioCard {
    // perspective: 800px;
    // perspective-origin: 50% 50%;

    // &.is-focussed,
    // &:hover {
    //     box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.12);
    //     transform: translate(-10px, -10px);
    // }

    // .c-portfolioCard__front {
    //     transform: rotateY(0deg);
    //     backface-visibility: hidden;
    // }

    // .c-portfolioCard__back {
    //     transform: rotateY(-180deg);
    //     backface-visibility: hidden;
    // }

    &.is-active,
    &:hover {
        box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.12);
        transform: translateX(-10px) translateY(-10px);

        .c-portfolioCard__front {
            // transform: rotateY(-180deg);
        }

        .c-portfolioCard__back {
            // transform: rotateY(0deg);
        }
    }
}

.c-portfolioCard__front {
    background-image: linear-gradient(0deg, #888888, #b3b3b3 50%, #c2c2c2 50%, #c2c2c2);
    background-size: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/assets/img/liney.png");
        background-size: 210px 210px;
        opacity: 0.3;
    }
}

@include portfolios($projects);

.c-portfolioCard__img {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50%;
    text-align: center;
}

.c-portfolioCard__back {
    // transform: rotateX(-180deg);
    display: none;
}

.c-portfolioCard__body {

}

.c-portfolioCard__title {

}

.c-portfolioCard__link {

}

.o-main__projectcontainer {
    @include media(screen, ">medium") {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: [intro bodyimg] 200px 1fr [bodyimg] 1fr [intro projimg] 200px [projimg];
        grid-auto-rows: auto;// minmax(260px, auto);
    }
}

.o-main__projectimg {
    grid-area: projimg;
    grid-row: 1;
}

.o-main__projectbody {
    grid-column: intro / projimg;
    grid-row: 1;

    div + h2 {
        margin-top: 1.5rem;
    }
}

.o-main__projectimgs {
    grid-column: 1 / -1;
    grid-row: 2;
}

div .o-main__subtitle {
    margin-top: 20px;
    margin-bottom: 5px;
}

.o-main__figure {
    @include media(screen, ">medium") {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-auto-rows: auto;
        grid-template-areas: "body body img img img";

        &:nth-child(odd) {
            grid-template-areas: "img img img body body";
        }
    }
}

.o-main__bodyfigcaption {
    margin: 40px 20px;
    grid-area: body;
}

.o-main__bodyimg {
    border: 1px solid #333333;
    box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.12);
    margin: 20px 0 40px;
    grid-area: img;
    display: inline-block;
}

// HEADER
// ---------------------
.c-header {
    background-image: url("/assets/img/headliney.png");
    background-size: 130px 130px;
    padding: 50px 0;
    text-align: center;

    @include media(screen, "<=medium") {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    @include media(screen, ">medium") {
        display: grid;
        grid-gap: 20px;
        margin: 0 auto;
        grid-template-columns: 1fr 200px 260px 200px 1fr;
        grid-auto-rows: auto;// minmax(260px, auto);
    }

    a {
        color: #333;
    }
}

.c-header__s {

}

.c-header__img {
    background-color: #ffffff;
    border: 1px solid #333333;
    max-width: 260px;

    @include media(screen, "<=medium") {
        margin-bottom: 20px;
    }
}

.c-header__img + .c-header__s {
    display: flex;
    align-items: center;
    @include font-size($TYPE-SIZE-XL);
}

.o-main__projectimg {
    max-width: 200px;
}

// VARIANTS
// ----------------------------------------------------

// RESPONSIVE
// ----------------------------------------------------

@include media(screen, "<=small") {
    //
}

@include media(screen, ">small", "<=medium") {
    //
}

@include media(screen, ">medium", "<=large") {
    //
}
