/// <reference path="../main.scss" />

////
/// MEDIA
/// Source: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
////

@if $COMPONENT-MEDIA {

    // BASE
    // ----------------------------------------------------

    .media {
        @include margin(0, 0, $TYPE-LineHeight, 0);
        @include clearfix;
    }

    .media__content {
        @include haslayout;
    }

    .media__image {
        float: left;

        @include margin(0, $TYPE-FontSize, 0, 0);

        .image {
            display: block;
        }
    }

    .media__image--alt {
        float: right;

        @include margin(0, 0, 0, $TYPE-FontSize);
    }

    .media__image--sm {
        @include media(screen, ">medium") {
            max-width: 50%;
        }
    }

    .media__image--portfolioCard {
        max-width: 50%;
        width: 200px;

        @include media(screen, ">medium") {
            width: 200px;
        }
    }

    // RESPONSIVE
    // ----------------------------------------------------

    @include media(screen, "<=medium") {
        .media__image--sm {
            float: none;
            display: block;
            text-align: center;
            @include margin(0, auto, $TYPE-LineHeight);
        }
    }

    @include media(screen, ">small", "<=medium") {
        //
    }

    @include media(screen, ">large") {
        //
    }
}
