/// <reference path="../main.scss" />

////
/// O_LIST
////

/// VARIABLES
/// ----------------------------------------------------
$SKIPLINKS-bg:                      $COLOR-White;
$SKIPLINKS-color:                   $COLOR-Black;

/// BASE
/// ----------------------------------------------------

.o-l {
    list-style: none;
    padding: 0;
    margin: 0;
}

.o-l--h {
    display: flex;
    flex-wrap: wrap;

    li {
        @include padding-right($TYPE-FontSize);
    }
}

.o-dl {
    display: flex;
    margin: 0;
}

.o-dl__term {
    //
}

.o-dl__desc {
    //
}

// VARIANTS
// ----------------------------------------------------
.o-l--portfolio {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: auto;
    display: grid;
    grid-gap: 20px;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: auto;// minmax(260px, auto);

    @include media(screen, ">small") {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
}

.o-l--history {
    list-style: square;
    margin-left: 20px;
}

// RESPONSIVE
// ----------------------------------------------------

@include media(screen, "<=small") {
    //
}

@include media(screen, ">small", "<=medium") {
    //
}

@include media(screen, ">medium", "<=large") {
    //
}
